.container_topup{
    padding: 20px;
}


.container_topup > .top{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px;
}

.container_topup > .top > button{
    padding: 8px 16px;
    border-radius: 4px ;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgb(37, 126, 229);
    font-size: 24px;
    transition: 0.3s all ease-in-out;
    color: #000;
}


.activeTag{
    background-color: rgb(37, 126, 229) !important;
    color: #fff !important;
}

.providerActive{
    background-color: rgb(37, 126, 229) !important;
    color: #fff !important;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .open-modal-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .close-modal-btn {
    padding: 10px 20px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .open-modal-btn:hover,
  .close-modal-btn:hover {
    opacity: 0.9;
  }

  .product-card > .change {
    display: flex;
    gap: 10px;
  }
  

  .product-card > .change > button{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
  }

  .product-card > .change > input{
    width: 20px;
  }